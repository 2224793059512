@import "../node_modules/bootstrap/scss/bootstrap";
@import "_variables.scss";
@import "_components.scss";
@import "slick.scss";
@import "slick-theme.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

* {
  font-family: "Poppins", sans-serif;
}

/**
* HEADER
*/

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-collapse {
    justify-content: flex-end;
  }
  .navbar {
    padding: 0 !important;
  }
  .navbar-light .navbar-nav .show > .nav-link,
  .navbar-light .navbar-nav .nav-link.active {
    border-top: 3px solid $red-color;
    padding-top: 15px;
    font-weight: 500;
  }
  .nav-item {
    &:not(:last-child) {
      margin-right: 24px;
    }
    &:hover {
      color: $black-color;
    }
    a {
      padding: 18px 0;
    }
  }
}

/**
* HERO
*/
@media (min-width: 992px) {
  .carouselExampleCaptions,
  .carousel-item {
    height: calc(100vh - 60px);
  }
}

#hero {
  background: url("../src/assets/PejeBau.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: calc(100vh - 60px);
  width: 100%;
  display: flex;
  align-items: center;
  .content {
    h1 {
      font-size: 100px;
      font-weight: bold;
      color: #fff;
      width: 70%;
      text-transform: uppercase;
      margin-bottom: 35px;
    }
  }

  //   RESPONSIVE
  @media (max-width: 992px) {
    .content {
      h1 {
        font-size: 2.5em;
        width: 100%;
      }
    }
  }
}

#hero-breadcrumb {
  background-color: $red-color;
  height: 385px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  h1 {
    color: #fff;
    font-size: 72px;
    font-weight: bold;
  }
  @media (max-width: 992px) {
    height: 250px;
    h1 {
      font-size: 3em;
    }
  }
}

/**
* ABOUT US
*/
#aboutus {
  padding: 150px 0;
  h2 {
    font-size: 60px;
    font-weight: bold;
  }
  img {
    height: 516px;
    width: 100%;
    object-fit: cover;
    border-bottom: 5px solid $red-color;
    margin: 70px 0;
  }
}
.aboutus-footer {
  padding-bottom: 150px;
}

/**
* VIDEO + MULTI LOCATION SECTION
*/
#videoml {
  background: url(../src/assets/PejeBau.png);
  height: 600px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  display: flex;
  align-items: center;
  z-index: 1;
  .playbtn {
    height: 118px;
    width: 118px;
    background-color: $red-color;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 22px;
    cursor: pointer;
    i {
      font-size: 35px;
      color: #fff;
    }
  }
  p {
    font-weight: 500;
    cursor: pointer;
  }
  @media (max-width: 992px) {
    align-items: flex-end;
    .playbtn {
      display: inline-flex;
      height: 72px;
      width: 72px;
      margin-left: 20px;
      i {
        font-size: 21px;
      }
    }
    p {
      display: inline-block;
      color: #fff;
      position: relative;
      top: 10px;
      margin-left: 22px;
    }
  }
}

/**
* LOCATIONS
*/
#locations {
  z-index: 2;
  .locations {
    margin-top: -50px;
    background-color: #fff;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: space-around;
    -webkit-box-shadow: 0px 3px 60px 0px rgba(0, 0, 0, 0.03);
    -moz-box-shadow: 0px 3px 60px 0px rgba(0, 0, 0, 0.03);
    box-shadow: 0px 3px 60px 0px rgba(0, 0, 0, 0.03);
    .loc {
      padding: 84px 47px;

      h2 {
        font-size: 67px;
        font-weight: bold;
        margin-bottom: 40px;
      }
    }
    .divider {
      width: 1px;
      height: 237px;
      display: block;
      background: #d8d8d8;
    }
  }
  @media (max-width: 992px) {
    .locations {
      display: block;
      margin-top: 0;
      .loc {
        h2 {
          font-size: 3em;
        }
      }
      .divider {
        width: 70vw;
        height: 1px;
        margin: 0 auto;
        display: block;
        background: #d8d8d8;
      }
    }
  }
}

/**
* INFOS
*/
#infos {
  margin: 150px 0;
  h2 {
    text-align: center;
    font-size: 80px;
    font-weight: bold;
    color: $black-color;
    margin-bottom: 135px;
    text-transform: uppercase;
  }
  h3 {
    font-size: 50px;
    color: $black-color;
    font-weight: 500;
  }
  h4 {
    font-size: 27px;
    color: $black-color;
    font-weight: 500;
    margin-top: 20px;
  }
  .divider {
    display: none;
  }
  @media (max-width: 992px) {
    padding: 0 10%;
    h2 {
      font-size: 3.5em;
      text-align: left;
    }
    .divider {
      width: 70vw;
      height: 1px;
      margin: 0 auto;
      display: block;
      background: #d8d8d8;
      margin: 20px 0;
    }
  }
}

/**
* TIPARET TONA
*/
#tiparettona {
  margin: 130px 0;
  h2 {
    margin-bottom: 30px;
    font-size: 64px;
    font-weight: bold;
  }
  .icon {
    height: 100px;
    width: 100px;
    border-radius: 24px;
    background-color: rgba($red-color, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      color: $red-color;
    }
  }
  h4 {
    font-size: 1.6em;
    font-weight: 500;
    margin: 40px 0 15px;
  }
  .row:nth-child(2) {
    margin-top: 88px;
  }
  //   RESPONSIVE
  @media (max-width: 992px) {
    margin: 60px 0;
    .row:nth-child(2) {
      margin-top: 50px;
      .col-lg-4 {
        &:not(:last-child) {
          margin-bottom: 30px;
        }
      }
    }
    h2 {
      font-size: 3em;
    }
  }
}

/**
* SERVICES SECTION
*/
#services {
  margin: 150px 0;
  .item {
    -webkit-box-shadow: 0px 3px 60px 0px rgba(0, 0, 0, 0.04);
    -moz-box-shadow: 0px 3px 60px 0px rgba(0, 0, 0, 0.04);
    box-shadow: 0px 3px 60px 0px rgba(0, 0, 0, 0.04);
    padding: 60px 40px;
    margin-bottom: 30px;
    h4 {
      font-size: 2em;
      font-weight: bold;
      color: $black-color;
    }
    p {
      font-size: 18px;
      line-height: 1.3em;
      font-weight: 400;
      margin: 30px 0 35px;
    }
  }
  @media (max-width: 992px) {
    margin: 100px 0;
  }
}

/**
* PARTNERS SECTION
*/
#partners {
  margin-bottom: 200px;
  h2 {
    margin-bottom: 30px;
    font-size: 64px;
    font-weight: bold;
  }
  .partners {
    .logo-partner {
      -webkit-box-shadow: inset 0px 0px 0px 1px #eeeeee;
      -moz-box-shadow: inset 0px 0px 0px 1px #eeeeee;
      box-shadow: inset 0px 0px 0px 1px #eeeeee;
      margin: 10px 0;
      display: flex !important;
      justify-content: center;
      width: 98% !important;
      img {
        padding: 15px 0;
      }
    }
  }
  @media (max-width: 992px) {
    h2 {
      font-size: 3em;
    }
  }
}

/**
* CATEGORIES
*/
#category {
  margin-top: 100px;
  .entry {
    text-align: center;
  }
  .img-cat {
    height: 624px;
    width: 100%;
    object-fit: cover;
    margin: 70px 0 100px 0;
  }
  @media (max-width: 992px) {
    .img-cat {
      height: 300px;
    }
  }
}
#resper {
  margin: 150px 0;
  h3 {
    margin-bottom: 90px;
    font-size: 40px;
    color: $black-color;
    font-weight: 500;
  }
  #respersec {
    display: flex;
    .information {
      display: flex;
      padding: 55px;
      width: 70%;
      -webkit-box-shadow: 0px 3px 60px 0px rgba(0, 0, 0, 0.04);
      -moz-box-shadow: 0px 3px 60px 0px rgba(0, 0, 0, 0.04);
      box-shadow: 0px 3px 60px 0px rgba(0, 0, 0, 0.04);
      .avatar {
        margin-right: 50px;
        img {
          height: 154px;
          width: 154px;
          object-fit: cover;
          border-radius: 50%;
        }
      }
      .content {
        .name {
          font-size: 38px;
          font-weight: bold;
        }
        a {
          font-size: 20px;
          color: $black-color;
          text-decoration: none;
          display: block;
          margin-bottom: 5px;
          &:hover {
            transition: all 0.2s ease-in-out;
            color: $red-color;
          }
        }
      }
    }
    a.contactbtn {
      width: 30%;
      background: $red-color;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 30px;
      text-decoration: none;
      &:hover {
        background-color: rgba($red-color, 0.9);
        transition: all 0.2s ease-in-out;
      }
    }
  }
  @media (max-width: 1399px) {
    #respersec {
      display: block;
      .information {
        width: 100%;
      }
      a.contactbtn {
        width: 100%;
        padding: 20px;
      }
    }
  }
  @media (max-width: 767px) {
    #respersec {
      display: block;
      .information {
        width: 100%;
        .content {
          .name {
            font-size: 2em;
          }
          a {
            font-size: 1em;
          }
        }
      }
      a.contactbtn {
        width: 100%;
        padding: 20px;
      }
    }
  }
  @media (max-width: 767px) {
    #respersec {
      display: block;
      .information {
        width: 100%;
        padding: 20px;
        .avatar {
          margin-right: 15px;
        }
      }
      a.contactbtn {
        width: 100%;
        padding: 20px;
      }
    }
  }
  @media (max-width: 767px) {
    #respersec {
      .information {
        display: block;
        .avatar {
          margin-bottom: 20px;
          img {
            height: 70px;
            width: 70px;
          }
        }
      }
    }
  }
}

/**
* DISTRIBUIMI PAGE
*/
#agjendat {
  margin-bottom: 100px;
  h3 {
    margin-bottom: 50px;
    font-size: 40px;
    color: $black-color;
    font-weight: 500;
  }
}
.attributes {
  margin-bottom: 40px;
  text-align: center;
  border: 1px solid #f7f7f7;
  padding: 20px 40px;
  height: 300px;
  background: #fdfdfd;
  .icon {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    background-color: $red-color;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    i {
      color: #fff;
      font-size: 2em;
    }
  }
  .text {
    margin-top: 25px;
    font-size: 1.5em;
    font-weight: 500;
  }
}
.distimg {
  margin-top: 0px !important;
}

/**
* CONTACT PAGE
*/
#contactpage {
  display: flex;
  .contactinfo {
    width: 50%;
    height: 740px;
    background-color: $red-color;
    display: flex;
    justify-content: center;
    align-items: center;
    .content {
      color: #fff;
      i {
        margin-right: 15px;
      }
      h2 {
        font-size: 52px;
        font-weight: bold;
        margin-bottom: 52px;
      }
      a {
        color: #fff;
        display: block;
        text-decoration: none;
        margin-bottom: 32px;
        margin-left: 20px;
        font-size: 20px;
        font-weight: 500;
      }
      p {
        margin-left: 20px;
        font-size: 20px;
        font-weight: 500;
      }
    }
  }
  .map {
    width: 50%;
    .mapouter {
      position: relative;
      text-align: right;
      height: 740px;
      width: 100%;
    }
    .gmap_canvas {
      overflow: hidden;
      background: none !important;
      width: 100%;
      iframe {
        height: 740px;
        width: 100%;
      }
    }
  }
  @media (max-width: 992px) {
    display: block;
    .contactinfo {
      width: 100%;
      height: 600px;
      .content {
        padding: 20px;
        h2 {
          font-size: 2.5em;
        }
      }
    }
    .map {
      width: 100%;
      .mapouter {
        position: relative;
        text-align: right;
        height: 500px;
        width: 100%;
      }
      .gmap_canvas {
        overflow: hidden;
        background: none !important;
        width: 100%;
        iframe {
          height: 500px;
          width: 100%;
        }
      }
    }
  }
}

/**
* FOOTER SECTION
*/
footer {
  background: #f5f5f5;
  padding-top: 130px;
  .about {
    img {
      margin-bottom: 40px;
    }
    padding: 0 15px;
  }
  .navi {
    h4 {
      font-size: 30px;
      font-weight: bold;
      color: $black-color;
      margin-bottom: 40px;
    }
    p {
      line-height: 2em;
    }
    a {
      color: $black-color;
      display: block;
      text-decoration: none;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
  .crbar {
    margin-top: 100px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    ul {
      list-style: none;
      display: flex;
      padding-left: 15px;
      li {
        &:not(:last-child) {
          margin-right: 30px;
        }
        a {
          color: $black-color;
          text-decoration: none;
        }
      }
    }
    .socials {
      background-color: $red-color;
      width: 200px;
      height: 70px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      a {
        color: #fff;
        padding: 0;
        margin: 0;
        line-height: 1em;
      }
    }
  }
  @media (max-width: 992px) {
    .about {
      padding: 0;
    }
    .navi {
      h4 {
        margin-bottom: 24px;
      }
      &:last-child {
        margin-top: 60px;
      }
    }
    .crbar {
      display: flex;
      flex-direction: column-reverse;
      ul {
        display: none;
      }
      .socials {
        width: 100%;
      }
    }
  }
}
