/**
* BUTTON FILLED
*/
.btn-filled {
  background: $red-color;
  color: #fff;
  text-decoration: none;
  font-size: 28px;
  padding: 15px 30px;
  border-radius: 6px;
  &:hover {
    color: $red-color;
    background: #fff;
    transition: all 0.2s ease-in-out;
  }
  @media (max-width: 992px) {
    padding: 10px 20px;
    font-size: 1.2em;
  }
}

/**
* BUTTON UNDERLINE
*/
.link {
  a {
    color: $black-color;
    text-decoration: none;
  }
  .underline {
    height: 2px;
    width: 60px;
    display: block;
    background-color: $red-color;
    transition: all 0.2s ease-in-out;
    border-radius: 2px;
  }
  &:hover {
    .underline {
      width: 125px;
      transition: all 0.2s ease-in-out;
    }
  }
}

/**
* KREJT NE NJE VEND BAR
*/
.knnv-bar {
  background: $red-color;
  padding: 50px 0;
  text-align: center;
  h2 {
    font-size: 90px;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
  }
  @media (max-width: 992px) {
    padding: 40px 0;
    h2 {
      font-size: 22px;
    }
  }
}

.modal-dialog {
  max-width: 800px;
  margin: 30px auto;
}

.modal-body {
  position: relative;
  padding: 0px;
}
.close {
  position: absolute;
  right: -30px;
  top: 0;
  z-index: 999;
  font-size: 2rem;
  font-weight: normal;
  color: #fff;
  opacity: 1;
}
